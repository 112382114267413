var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"vld-parent trip-status-pill-button  blue focus:outline-none",staticStyle:{"height":"35px","width":"170px"},attrs:{"aria-label":"On Reserved Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.isLoading)?_c('loading',{attrs:{"height":25,"opacity":0,"color":"#fff","active":true,"loader":"bars","is-full-page":false}}):[_c('span',[_vm._v("Reserved")]),(_vm.$acl.canUpdate('trips'))?[_c('i',{staticClass:"fas fa-chevron-down"})]:_vm._e()]],2)]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [(_vm.$acl.canUpdate('trips'))?_c('div',{staticClass:"py-1 mt-1 bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[(_vm.isItPrivateModeAction)?_c('t-dropdown-item',{on:{"click":function($event){return _vm.onTripAction({
              action: 'accept',
            })}}},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.actions.acceptUnlockRequest'))+" ")]):_vm._e(),(_vm.isItPrivateModeAction)?_c('t-dropdown-item',{on:{"click":function($event){return _vm.onTripAction({
              action: 'reject',
            })}}},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.actions.rejectUnlockRequest'))+" ")]):_vm._e(),(_vm.isItPublicModeAction)?_c('t-dropdown-item',{on:{"click":function($event){return _vm.onTripAction({
              action: 'cancel',
            })}}},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.actions.forceCancel'))+" ")]):_vm._e()],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }