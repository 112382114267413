<template>
  <div>
    <t-dropdown variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          aria-label="On Reserved Dropdown"
          class="vld-parent trip-status-pill-button  blue focus:outline-none"
          style="height: 35px; width: 170px"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <loading
            :height="25"
            :opacity="0"
            color="#fff"
            v-if="isLoading"
            :active="true"
            loader="bars"
            :is-full-page="false"
          />
          <template v-else>
            <span>Reserved</span>
            <template v-if="$acl.canUpdate('trips')"
              ><i class="fas fa-chevron-down"
            /></template>
          </template>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div
          v-if="$acl.canUpdate('trips')"
          @click="hide()"
          class="py-1 mt-1 bg-white rounded-md shadow-md"
        >
          <t-dropdown-item
            v-if="isItPrivateModeAction"
            @click="
              onTripAction({
                action: 'accept',
              })
            "
          >
            {{ $t('components.tripManagement.actions.acceptUnlockRequest') }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="isItPrivateModeAction"
            @click="
              onTripAction({
                action: 'reject',
              })
            "
          >
            {{ $t('components.tripManagement.actions.rejectUnlockRequest') }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="isItPublicModeAction"
            @click="
              onTripAction({
                action: 'cancel',
              })
            "
          >
            {{ $t('components.tripManagement.actions.forceCancel') }}
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { EventBus } from '@/utils/EventBus'
export default {
  name: 'OnReserveAffirmationBlock',
  components: {
    TDropdownItem,
  },
  props: {
    tripData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    isItPublicModeAction() {
      return this.tripData.vehicle_settings.vehicle_ownership_mode === 'FLEET'
    },
    isItPrivateModeAction() {
      return this.tripData.vehicle_settings.vehicle_ownership_mode === 'PRIVATE'
    },
  },
  methods: {
    async onTripAction({ action = 'accept' } = {}) {
      if (this.$acl.canNotUpdate('trips')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }
      this.isLoading = true
      console.log('onTripAction', action)
      let url
      let method
      if (action === 'accept') {
        url = `/dashboard/trips/${this.tripData.id}/accept-unlock-request/`
        method = 'PUT'
      }
      if (action === 'reject') {
        url = `/dashboard/trips/${this.tripData.id}/reject-unlock-request/`
        method = 'PUT'
      }

      if (action === 'cancel') {
        url = `/dashboard/trips/${this.tripData.id}/cancel/`
        method = 'POST'
      }

      await this.$http({ method, url })
        .then((r) => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: `${r.data.message}`,
            },
            3000
          )
          console.log('test-onTripAction', r.data)
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Failed [${err.response.status}]`,
              text: `${err.response.data.detail || 'Failed to execute'}`,
            },
            5000
          )
          console.warn('onTripActionErr', { err })
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false

            EventBus.$emit('fetchTripDetail', this.tripData.id)
          }, 5000)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/trip-status';
</style>
