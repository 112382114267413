export const BillingPlanConfig = {
  api: {
    // get
    index: '/dashboard/pricing-plans/',
    // post
    create: '/dashboard/pricing-plans/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/pricing-plans/${id}/`
    },

    //details
    details: (id = 'uuid') => {
      return `/dashboard/pricing-plans/${id}/`
    },
  },
  privateApi: {
    // get
    index: '/dashboard/private-pricing-plans/',
    // post
    create: '/dashboard/private-pricing-plans/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/private-pricing-plans/${id}/`
    },

    //details
    details: (id = 'uuid') => {
      return `/dashboard/private-pricing-plans/${id}/`
    },
  },
  type: {
    private: 'private-pricing',
    public: 'public-pricing',
  },
  events: {
    name: 'billing-plan',
    // refresh-index-data
    refresh: `rid-billing-plan`,
    // slideover-right
    sorId: 'billing-plan',
    sorOpen: 'sor-open-billing-plan',
    sorClose: 'sor-close-billing-plan',
    sorToggle: 'sor-toggle-billing-plan',
    // editing-data
    editingData: 'edit-billing-plan-data',
    // viewing-data
    viewingData: 'view-billing-plan-data',
  },
}
